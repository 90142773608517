<template>
	<div>
		<!-- 登录信息 -->
		<global-tips></global-tips>
		<el-card>
			<!-- 搜索框 -->
			 <el-form v-show="showSearch" ref="queryForm" :model="queryForm" inline size="small" @submit.native.prevent>
				<el-form-item label="名称" label-width="50px" prop="keyword">
				  <el-input v-model="queryForm.keyword" placeholder="请输入名称" type="text" clearable style="width: 230px" @keyup.enter.native="query"/>
				</el-form-item>
				<el-form-item>
				  <el-button type="primary" icon="el-icon-search" size="mini" @click="query">查询</el-button>
				  <el-button icon="el-icon-refresh" size="mini" @click="resetQuery('queryForm')">重置</el-button>
				</el-form-item>
			</el-form>
			<!--自定义刷新组件-->
			<el-row :gutter="10" class="mb-1">
				<el-button type="primary" icon="el-icon-plus" size="mini" @click="handleAdd">新增</el-button>
				<right-toolbar :show-search.sync="showSearch" @queryTable="query" />
			</el-row>
			<!--表格-->
			  <el-table
				v-loading="loading"
				size="small"
				:data="list"
				element-loading-text="Loading"
				highlight-current-row
				style="width: 100%"
				row-key="id"
				:tree-props="{children: 'children'}"
			  >
				<el-table-column label="菜单名称" min-width="200">
				  <template slot-scope="scope">
					<span>{{ scope.row.name }}</span>
				  </template>
				</el-table-column>
				<el-table-column label="图标" min-width="50" align="center">
				  <template slot-scope="scope">
					<svg-icon :icon-class="scope.row.icon ? scope.row.icon : ''" />
				  </template>
				</el-table-column>
				<el-table-column label="类型" min-width="50" align="center">
				  <template slot-scope="scope">
					<el-tag v-if="scope.row.type === 1" type="info" size="mini">目录</el-tag>
					<el-tag v-if="scope.row.type === 2" type="primary" size="mini">菜单</el-tag>
					<el-tag v-if="scope.row.type === 3" type="warning" size="mini">按钮</el-tag>
				  </template>
				</el-table-column>
				<el-table-column align="center" prop="sortNum" label="排序" min-width="50">
				  <template slot-scope="scope">
					<span>{{ scope.row.sort }}</span>
				  </template>
				</el-table-column>
				<el-table-column align="center" prop="permission" label="权限标识" min-width="80">
				  <template slot-scope="scope">
					<span>{{ scope.row.permission }}</span>
				  </template>
				</el-table-column>
				<el-table-column align="center" prop="component" label="组件路径" min-width="150">
				  <template slot-scope="scope">
					<span>{{ scope.row.component }}</span>
				  </template>
				</el-table-column>
				<el-table-column align="center" prop="status" label="状态" min-width="50">
				  <template slot-scope="scope">
					<el-tag v-if="scope.row.status === 2" size="mini" type="danger">停用</el-tag>
					<el-tag v-if="scope.row.status === 1" size="mini" type="success">正常</el-tag>
				  </template>
				</el-table-column>
				<el-table-column align="center" prop="visible" label="显隐" min-width="50">
				  <template slot-scope="scope">
					<el-tag v-if="scope.row.visible === 1" type="success" size="mini">显示</el-tag>
					<el-tag v-if="scope.row.visible === 2" type="danger" size="mini">隐藏</el-tag>
				  </template>
				</el-table-column>
				<el-table-column align="center" prop="ctime" label="创建时间" min-width="135">
				  <template slot-scope="scope">
					<i class="el-icon-time" />
					<span>{{ scope.row.ctime }}</span>
				  </template>
				</el-table-column>
				<el-table-column label="操作" min-width="150" align="center">
				  <template slot-scope="scope">
					<el-tooltip class="item" effect="dark" content="编辑" placement="top">
					  <el-button type="success" icon="el-icon-edit" size="mini" circle @click="handleEdit(scope.row)"></el-button>		
					</el-tooltip>
                    <el-tooltip class="item" effect="dark" content="删除" placement="top">
                      <el-button type="danger" icon="el-icon-delete" size="mini" circle @click="handleDelete(scope.row.id)"></el-button>		
                    </el-tooltip>
                    <el-tooltip class="item" effect="dark" content="新增子菜单" placement="top">
                      <el-button type="danger" icon="el-icon-plus" size="mini" circle @click="handleAddChildren(scope.row.id)"></el-button>		
                    </el-tooltip>
				  </template>
				</el-table-column>
			  </el-table>
		</el-card>
		<!-- 编辑-->
		<el-dialog :visible.sync="open" :title="title" :close-on-click-modal="false" width="800px">
			  <el-form ref="form" :model="form" :rules="rules" label-width="80px" size="small">
				<el-form-item>
				  <el-alert
					title="不选择上级菜单就代表添加根菜单"
					type="info"
					:closable="false"
					show-icon
				  /></el-form-item>
				<el-form-item label="上级菜单" prop="pid">
				  <treeselect
					v-model="form.pid"
					:options="menuOptions"
					:normalizer="normalizer"
					:show-count="true"
					:openOnFocus="true"
					placeholder="请选择上级菜单"
					noChildrenText="没有数据了"
					noOptionsText="没有数据"
					noResultsText="没有搜索结果"
				   />
				</el-form-item>
				<el-form-item label="菜单类型">
				  <el-radio-group v-model="form.type" @change="handleChange">
					<el-radio-button :label="1">目录</el-radio-button>
					<el-radio-button :label="2">菜单</el-radio-button>
					<el-radio-button :label="3">按钮</el-radio-button>
				  </el-radio-group>
				</el-form-item>

				<el-row>
				  <el-col :span="24">
					<el-form-item v-if="form.type !== 3" label="菜单图标">
					  <el-popover
						placement="bottom-start"
						width="460"
						trigger="click"
						@show="$refs['iconSelect'].reset()"
					  >
						<IconSelect ref="iconSelect" @selected="selected" />
						<el-input slot="reference" v-model="form.icon" placeholder="点击选择图标" readonly>
						  <svg-icon
							v-if="form.icon"
							slot="prefix"
							:icon-class="form.icon"
							class="el-input__icon"
							style="height: 32px;width: 16px;"
						  />
						  <i v-else slot="prefix" class="el-icon-search el-input__icon" />
						</el-input>
					  </el-popover>
					</el-form-item>
				  </el-col>
				  <el-col :span="12">
					<el-form-item label="菜单名称" prop="name">
					  <el-input v-model="form.name" />
					</el-form-item>
				  </el-col>
				  <el-col :span="12">
					<el-form-item v-if="form.type !== 3" label="路由地址" prop="path">
					  <el-input v-model="form.path" />
					</el-form-item>
				  </el-col>
				  <el-col :span="12">
					<el-form-item v-if="form.type !== 3" label="是否外链">
						<el-radio-group v-model="form.is_frame">
							<el-radio :label="1">外链</el-radio>
							<el-radio :label="2">非外链</el-radio>
						</el-radio-group>
					</el-form-item>
				  </el-col>
				  <el-col :span="12">
					<el-form-item label="菜单排序" prop="sort">
					  <el-input v-model="form.sort" type="number" />
					</el-form-item>
				  </el-col>
				  <el-col :span="12">
					<el-form-item v-if="form.type !== 1" label="权限标识" prop="permission">
					  <el-input v-model="form.permission" />
					</el-form-item>
				  </el-col>
				  <el-col :span="12">
					<el-form-item v-if="form.type === 2" label="组件路径" prop="component">
					  <el-input v-model="form.component" />
					</el-form-item>
				  </el-col>
				  <el-col :span="12">
					<el-form-item v-if="form.type === 2" label="是否缓存">
					  <el-radio-group v-model="form.is_cache">
					  	<el-radio :label="1">缓存</el-radio>
					  	<el-radio :label="2">不缓存</el-radio>
					  </el-radio-group>
					</el-form-item>
				  </el-col>
				  <el-col :span="12">
					<el-form-item v-if="form.type !== 3" label="是否显示" prop="component">
					  <el-radio-group v-model="form.visible">
					  	<el-radio :label="1">显示</el-radio>
					  	<el-radio :label="2">隐藏</el-radio>
					  </el-radio-group>
					</el-form-item>
				  </el-col>
				  <el-col :span="12">
					<el-form-item v-if="form.type !== 3" label="菜单状态" prop="status">
					  <el-radio-group v-model="form.status">
					  	<el-radio :label="1">正常</el-radio>
					  	<el-radio :label="2">停用</el-radio>
					  </el-radio-group>
					</el-form-item>
				  </el-col>
				</el-row>
			  </el-form>
			  <div slot="footer" class="dialog-footer">
				<el-button size="mini" @click="cancel">取 消</el-button>
				<el-button type="primary" size="mini" @click="handleSubmit">确 定</el-button>
			  </div>
		</el-dialog>
	</div>
</template>

<script>
	import GlobalTips from "@/components/GlobalTips";
	import common from "@/common/mixins/common.js"
    import Treeselect from '@riophae/vue-treeselect'
    import '@riophae/vue-treeselect/dist/vue-treeselect.css'
    import IconSelect from '@/components/IconSelect'
	export default {
		inject:['app'],
		mixins:[common],
		components: {
			GlobalTips,
			Treeselect,
			IconSelect
		},
		data() {
			return {
			  preUrl: 'operation',
			  statusOptions: [],
			  menuOptions: [],
			  queryForm: {
				page: 1,
				limit: 10,
				keyword: '',
				status: 0
			  },
			  list:[],
			  form:{},
			  rules:{
				name: [
				  { required: true, message: '请输入菜单名称', trigger: 'blur' }
				],
			  },
			}
		},
		mounted() {
			
		},
		methods:{
           getList() {
			 this.loading = true
			 this.axios.get(`/manage/${this.preUrl}/list`, {
			   params: this.queryForm
			 }).then(res => {
			   this.total = res.total
			   this.list = res.data
			   const menu = { id: 0, name: "顶级", children: [] };
			   menu.children = res.data;
			   this.menuOptions = []
			   this.menuOptions.push(menu);
			   this.loading = false
			 }).catch(err => {
			   this.loading = false
			 })
		   },
		   // 重置按钮
		   resetQuery(formName) {
			 this.resetForm(formName)
			 this.query()
		   },
		   handlePermission(){},
		   handleDataScope(){},
		   chooseImage(){},
		   // 新增子菜单按钮
		   handleAddChildren(id) {
			 this.reset()
			 this.form.pid = id
			 this.title = '新增子菜单'
			 this.open = true
		   },
		   // 选择图标
		   selected(name) {
			 this.form.icon = name
		   },
		   // 菜单类型变化事件
		   handleChange(val) {
			 if (val === 2) {
			   this.form.visible = 1
			 }
		   },
		   // treeSelect 自定义键名
		   normalizer(node) {
			 if (node.children && !node.children.length) {
			   delete node.children
			 }
			 return {
			   id: node.id,
			   label: node.name,
			   children: node.children
			 }
		   },
		   reset() {
			 this.resetForm('form')
			 this.form = {
			   id: undefined,
			   pid: 0,
			   name: undefined,
			   icon: undefined,
			   type: 2,
			   is_frame: 2,
			   is_cache: 1,
			   visible: 1,
			   sort: 100,
			   status: 1
			 }
		   }
		}
	}
</script>

<style>
</style>